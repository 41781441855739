@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

body {
	font-family: 'Press Start 2P', cursive;
	background: url('./assets/art.jpg');
}

.playArea {
	border: 5px solid transparent;
	width: 36.57rem;
	height: 27.5rem;
	position: fixed;
	top: 43.2%;
	left: 50%;
	transform: translate(-50%, -50%);
}

#fruit {
	display: none;
}

.monitor {
	width: 50rem;
	height: 50rem;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.gameOver {
	position: fixed;
	top: 43.2%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: red;
	font-size: 35px;
}
.playButton {
	position: fixed;
	top: 80%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: white;
	background: #502c7b;
	border: 2px solid white;
	padding: 10px;
	font-size: 0.9em;
	color: white;
	box-shadow: 4px 4px 0px 0px white;
	font-family: 'Press Start 2P', cursive;
	font-weight: 700;
	letter-spacing: 5px;
	text-transform: uppercase;
}

.scoreBox {
	float: right;
	margin: 2rem;
	background: linear-gradient(white, blue);
	margin-top:3rem;
	-webkit-box-shadow: 0px 4px 13px 0px rgb(118, 12, 240);
	box-shadow: 0px 4px 13px 0px red;
}

h2 {
	font-size: 1.5rem;
	padding: 0.7rem;
}